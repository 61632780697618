import React from 'react';
import { graphql } from 'gatsby';
import { Img } from 'gatsby-image';
import styled from 'styled-components';
import ProductCard from '../components/ProductCard';
import NavPages from '../components/NavPages';
import SEO from '../components/SEO';

const SeriesStyled = styled.div`
  width: 100%;
  margin: 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  position: relative;
  @media (max-width: 1023px) {
    background-size: 100% contain;
  }
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 50vh;
    background-image: linear-gradient(to bottom, var(--greyLight), transparent);
    z-index: 0;
  }
  .series-container {
    max-width: var(--wrapper);
    margin: 0 auto;
    h1 {
      color: var(--white);
      text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.75);
      @media (max-width: 1023px) {
        font-size: 3.5rem;
      }
    }
    z-index: 1;
    position: relative;
  }
  .series-header {
    min-height: 400px;
    display: grid;
    align-items: center;
    justify-items: center;
    @media (max-width: 1023px) {
      min-height: 200px;
    }
  }
  .series-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem;
    @media (max-width: 1023px) {
      grid-template-columns: 1fr;
    }
  }
`;

export default function SingleProductPage({ data, pageContext }) {
  const { products } = data;
  return (
    <>
      <SEO title={`Explore the ${pageContext.name} cycling shoe series`} />
      <SeriesStyled
        style={{
          backgroundImage: `url(${
            pageContext.background ? pageContext.background : ''
          })`,
        }}
      >
        <div className="series-container wrapper">
          <div className="series-header">
            <h1>{pageContext.name}</h1>
          </div>
          <div className="series-wrapper">
            {products.nodes.map((product) => (
              <ProductCard
                title={product.title}
                description={product.description}
                image={product.productImage.asset.fluid}
                series={product.series.title}
                category={product.category.title}
                slug={product.slug.current}
              />
            ))}
          </div>
        </div>
        <NavPages />
      </SeriesStyled>
    </>
  );
}

export const query = graphql`
  query ($name: String!) {
    products: allSanityProducts(
      filter: { series: { title: { eq: $name } } }
      sort: {
        fields: [productPrice, title]
        order: [DESC, DESC, DESC]
      }
    ) {
      nodes {
        title
        id
        description
        slug {
          current
        }
        category {
          id
          title
          slug {
            current
          }
        }
        series {
          id
          title
          slug {
            current
          }
        }
        productImage {
          asset {
            fluid(maxWidth: 1000) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`;
